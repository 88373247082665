import React from "react"
import firebase from "../Firebase/firebase"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import BackgroundImage from "gatsby-background-image"

import { Icon } from "react-icons-kit"
import { phone } from "react-icons-kit/fa/phone"

import ServiceContactForm from "../ContactForms/ServiceContactForm"

import "./MainSection.css"

function ServiceMainSection(props) {
  const image = props.image

  const handleClick = type => {
    firebase.analytics().logEvent(type)
  }

  return (
    <BackgroundImage id="main-service" Tag="section" fluid={image}>
      {!props.overlay && <div className="overlay"></div>}
      <Container fluid>
        <Row className="main-text justify-content-center">
          <Col lg={6} className="align-self-center">
            <p className="lead">Bob Keach &amp; Sons Carpentry</p>
            {props.children}
            <span className="phone-btn">
              <a
                className="btn btn-danger btn-mod"
                href="tel:1-508-942-0587"
                onClick={() =>
                  handleClick("Call 508-942-0587 from Service Page")
                }
              >
                <Icon size={20} icon={phone} />
                &nbsp;(508) 942-0587
              </a>
            </span>
            <span className="phone-btn">
              <a
                className="btn btn-danger btn-mod"
                href="tel:1-508-230-9528"
                onClick={() =>
                  handleClick("Call 508-230-9528 from Service Page")
                }
              >
                <Icon size={20} icon={phone} />
                &nbsp;(508) 230-9528
              </a>
            </span>
          </Col>
          <Col xs={"auto"}>
            <ServiceContactForm />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  )
}

export default ServiceMainSection
