import React from "react"

import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

import "./ServiceContactForm.css"

import useFormValidation from "./validation/useFormValidation"
import validateAuth from "./validation/validateAuth"

const INITIAL_STATE = {
  email: "",
  name: "",
  message: "",
  phone: "",
}

const ServiceContactForm = () => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting,
    isSubmitted,
  } = useFormValidation(INITIAL_STATE, validateAuth)

  if (!isSubmitted) {
    return (
      <Form onSubmit={handleSubmit} className="shadow-sm" id="service-form">
        <Form.Row className="justify-content-center">
          <Col xs={12} className="text-center pb-2">
            <h2 className="pb-2">Request Contact</h2>
            <p>
              Leave us a message using the form below. <br />
              We'll contact you within 24h.
            </p>
          </Col>
          <Col md={10} lg={10}>
            <Form.Control
              onChange={handleChange}
              name="name"
              value={values.name}
              className={errors.name && "error-input"}
              type="text"
              placeholder="Full name"
              aria-label="Your full name"
              aria-required="true"
            />
          </Col>
          <Col md={10} lg={10}>
            <Form.Control
              onChange={handleChange}
              name="phone"
              type="text"
              value={values.phone}
              className={errors.phone && "error-input"}
              placeholder="Phone number"
              aria-label="Your email"
              aria-required="true"
            />
          </Col>
          <Col md={10} lg={10}>
            <Form.Control
              onChange={handleChange}
              name="email"
              value={values.email}
              className={errors.email && "error-input"}
              type="email"
              placeholder="Enter email"
              aria-label="Your phone number"
              aria-required="true"
            />
          </Col>
          <Col md={10} lg={10}>
            <Form.Control
              onChange={handleChange}
              name="message"
              value={values.message}
              className={errors.message && "error-input"}
              as="textarea"
              rows="2"
              placeholder="Message"
              aria-label="Message"
              aria-required="true"
            />
          </Col>

          <Col xs={8} sm={5} lg={6} className="pt-3">
            <Button
              type="submit"
              disabled={isSubmitting}
              variant={"outline-danger"}
              block
            >
              Request Contact
            </Button>
          </Col>
        </Form.Row>
      </Form>
    )
  } else {
    return (
      <Form className="shadow-sm" id="service-form">
        <Form.Row className="justify-content-center">
          <Col xs={12} className="text-center pb-2">
            <p className="h4 pb-2">Thank you for contacting us.</p>
            <p>
              We will look over your message and get back to you within 24h.
            </p>
          </Col>
        </Form.Row>
      </Form>
    )
  }
}

export default ServiceContactForm
